<template>
  <div>
    <BaseThemeComponent
      v-if="thePage.contents.header_title || thePage.contents.header_img"
      name="PageHeader"
      :bg-image="thePage.contents.header_img"
      :title="thePage.contents.header_title"
    />

    <BaseThemeComponent
      v-if="thePage.contents.show_featured_press"
      :press-items="JSON.parse(thePage.contents.press_items)"
      name="FeaturedPress"
    />

    <BaseThemeComponent
      v-if="thePage.contents.show_other_mentions"
      :press-items="JSON.parse(thePage.contents.other_mentions)"
      name="OtherMentions"
    />

    <BasePageSectionImageBreak
      v-if="thePage.contents.img_break_1"
      :bg-image="thePage.contents.img_break_1"
    />

    <BaseThemeComponent
      v-if="thePage.contents.ns_1_title || thePage.contents.ns_1_description"
      name="PageSectionNarrative"
      section-id="1"
      :title-class="thePage.contents.ns_1_title_class"
      :title="thePage.contents.ns_1_title"
      :bg-color="thePage.contents.ns_1_bg_color"
      :text-cols="6"
      full-bleed-bg
    >
      <BasePageContent
        content-key="ns_1_description"
        tag="p"
        tag-class="r-title"
      />
    </BaseThemeComponent>

    <!-- Hogs for the Cause -->
    <BaseThemeComponent
      v-if="
        thePage.contents.ns_2_title ||
          thePage.contents.ns_2_description ||
          thePage.contents.ns_2_img
      "
      name="PageSectionNarrative"
      section-id="2"
      class="white--text"
      :text-cols="7"
      fluid
    >
      <VLayout row>
        <VFlex xs12 md10>
          <BasePageContent
            content-key="ns_2_description"
            tag="p"
            class="r-headline"
          />
          <BaseButtonOutline
            v-if="thePage.contents.ns_2_btn_text"
            :outline-color="thePage.contents.ns_2_btn_outline_color"
            class="ml-0"
            :to="thePage.contents.ns_2_btn_route"
            :href="thePage.contents.ns_2_btn_link"
          >
            {{ thePage.contents.ns_2_btn_text }}
          </BaseButtonOutline>
        </VFlex>
      </VLayout>
    </BaseThemeComponent>
  </div>
</template>

<script>
import PageMixin from '@mixins/page'
export default {
  name: 'BlueOakHSVPressPage',
  mixins: [PageMixin],
  data() {
    return {}
  },
}
</script>
